function ScrollMenu(){
    return (
        <div className="scrollmenu w-sm-100 w-md-25">
            <div className="form-check">
                <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" defaultChecked={true} onChange={(e)=>{
                    if(e.target.checked){
                        document.querySelectorAll('.script-font').forEach(element => {
                            element.hidden = false;
                        });
                    }else{
                        document.querySelectorAll('.script-font').forEach(element => {
                            element.hidden = true;
                        });
                    }
                }} />
                <label className="form-check-label" for="defaultCheck1">
                    Script Fonts
                </label>
            </div>
            <div className="form-check">
                <input className="form-check-input" type="checkbox" value="" id="defaultCheck2" defaultChecked={true} onChange={(e)=>{
                    if(e.target.checked){
                        document.querySelectorAll('.print-font').forEach(element => {
                            element.hidden = false;
                        });
                    }else{
                        document.querySelectorAll('.print-font').forEach(element => {
                            element.hidden = true;
                        });
                    }
                }} />
                <label className="form-check-label" for="defaultCheck2">
                    Print fonts
                </label>
            </div>
        </div>
    )
}

export default ScrollMenu;