import ScrollMenu from "./ScrollMenu";

function TypeBox(props){

    return (
        <div className="typebox input-group d-inline-block">
            <label htmlFor="typeBox" className="mb-05">Type your text here</label>
            <div className="d-block d-md-flex justify-content-between">
                <input autoFocus type="text" id="typeBox" className="type form-control w-sm-100 w-md-75" placeholder="Type something here" aria-label="type-smth"
                    aria-describedby="basic-addon1" defaultValue={props.fontText} onKeyUp={e => {
                        let value = e.target.value;
                        props.setfontText(value);
                        value = value.replaceAll(' ', '');
                        props.setfontCount(value.length);
                    }} />
                <ScrollMenu />
            </div>
        </div>
    )
}

export default TypeBox;