import './fonts/face.css';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import 'material-design-icons/iconfont/material-icons.css';

//pages
import Home from './pages/Home';

function App() {
  return (
    <main class="home-wrapper">
      <Home />
    </main>
  );
}

export default App;
