import { useState, useEffect } from 'react';

//components
import Nav from '../components/Nav';
import TypeBox from '../components/TypeBox';
// import ScrollMenu from '../components/ScrollMenu';
import Columns from '../components/Columns';


function Home() {
    const [fontText, setfontText] = useState('Hello');
    const [printFonts, setprintFonts] = useState(['a3','a4','a5','a6','a7','a8','a9','s','w','x']);
    const [scriptFonts, setscriptFonts] = useState(['a','a1','a2','b','c','d','e','f','g','h','i','j','k','l','m','n','o','p','q','r','t','u','v','y','z']);

    const [fontCount, setfontCount] = useState('Hello'.length);

    useEffect(() => {
        let heightToSet = 0;
        let columns = document.querySelectorAll('.column-body');
        columns.forEach((item)=>{
            let children = item.querySelector('p');
            if(children.offsetHeight > heightToSet){
                heightToSet = children.offsetHeight + 200;
            }
        });
        document.querySelectorAll('.column-body').forEach(item => {
            item.style.minHeight = `${heightToSet}px`;
            if(fontCount === 0){
                item.style.minHeight = `132px`;
            }
        })

        
    }, [fontText]);
    return(
        <div>
            <Nav />
            <TypeBox setfontCount={setfontCount} fontText={fontText} setfontText={setfontText} />
            {/* <ScrollMenu /> */}
            <div className="columns-wrapper pt-0">
                <Columns fontCount={fontCount} fontText={fontText} printFonts={printFonts} scriptFonts={scriptFonts} />
            </div>
        </div>
    );
}

export default Home;