function Columns(props) {
    return (
        <div className="row">


            {props.printFonts.map((element) => {

                return (
                    <div className="col-12 col-md-6 col-lg-4 print-font">
                        <div className="column">
                            <div className="column-header d-flex justify-content-between">
                                <h2><span class="badge" style={{color: '#fff', backgroundColor: '#5F7F79'}}>Font ID:</span> <span style={{verticalAlign: 'middle'}} className="ml-025 h3">{element.toUpperCase()}</span></h2>
                                {/* <h2> Font ID: {element.toUpperCase()} </h2> */}
                            </div>
                            <div className="column-body">
                                <p className={"print-" + element}>{props.fontText}
                                </p>
                            </div>
                            <div className="column-footer">
                                <h3> Print Font </h3>
                            </div>
                            {/* <div className="column-footer">
                                <div>
                                    <span className="material-icons">
                                        local_offer
                                    </span>
                                    Letter-count based pricing
                                </div>

                                <div>
                                    <span className="material-icons">
                                        sort_by_alpha
                                    </span>
                                    Your letter count is <strong>{props.fontCount}</strong>
                                </div>

                            </div> */}
                        </div>
                    </div>
                )

            })}

            {props.scriptFonts.map((element) => {

                return (
                    <div className="col-12 col-md-6 col-lg-4 script-font">
                        <div className="column">
                            <div className="column-header d-flex justify-content-between">
                            <h2><span class="badge" style={{color: '#fff', backgroundColor: '#5F7F79'}}>Font ID:</span> <span style={{verticalAlign: 'middle'}} className="ml-025 h3">{element.toUpperCase()}</span></h2>
                                {/* <h2> Font ID: {element.toUpperCase()} </h2> */}
                            </div>
                            <div className="column-body">
                                <p className={"script-" + element}>{props.fontText}
                                </p>
                            </div>
                            <div className="column-footer">
                                <h3> Script Font </h3>
                            </div>
                            {/* <div className="column-footer">
                                <div>
                                    <span className="material-icons">
                                        local_offer
                                    </span>
                                    Project size based pricing
                                </div>

                                <div>
                                    <span className="material-icons">
                                        local_mall
                                    </span>
                                    TBD on Checkout
                                </div>

                            </div> */}
                        </div>
                    </div>
                )
            })}

        </div>
    )
}

export default Columns;