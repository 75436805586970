import logo from "../images/logo.png"
import { useState } from 'react';

function Nav() {
    const [ToggleMenu, setToggleMenu] = useState(false)
    return (
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
            <a className="navbar-brand" href="#"><img src={logo} alt="logo" /></a>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup"
                aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon" onClick={()=>setToggleMenu(true)}></span>
            </button>
            <div className={"collapse navbar-collapse "+(ToggleMenu ? 'show' : '')} id="navbarNavAltMarkup">
                <h1 className="d-block d-md-none" onClick={()=>setToggleMenu(false)}>&times;</h1>
                <div className="navbar-nav">
                    <a className="nav-item nav-link active" href="https://sevensixtydesign.ca">Shop<span className="sr-only"></span></a>
                </div>
                <div className="navbar-nav">
                    <a className="nav-item nav-link active" href="https://sevensixtydesign.ca/collections/under-30-1">Under $30<span className="sr-only"></span></a>
                </div>
                <div className="navbar-nav">
                    <a className="nav-item nav-link active" href="https://sevensixtydesign.ca/collections/under-30-00">Under $50<span className="sr-only"></span></a>
                </div>
                <div className="navbar-nav">
                    <a className="nav-item nav-link active" href="https://sevensixtydesign.ca/collections/diy-blanks">DIY<span className="sr-only"></span></a>
                </div>
                <div className="navbar-nav">
                    <a className="nav-item nav-link active" href="https://sevensixtydesign.ca/collections/gift-card">Gift Cards<span className="sr-only"></span></a>
                </div>
            </div>
        </nav>
    )
}

export default Nav;